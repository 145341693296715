import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import styles from "../styles/post.module.scss";

const AboutPage = () => (
  <Layout>
    <SEO title="About law.photography" />
    <div className={styles.postContainer}>
      <article className={styles.post}>
        <p>
          This page is initially made by{" "}
          <a href="https://gegenwind.dk" target="gegenwind">
            GegenWind Photography
          </a>{" "}
          and the wonderful community who has helped us so far and hopefully
          will continue to{" "}
          <Link to="/contributing">contribute to the content</Link>.{" "}
        </p>
        <p>
          We're Judith and Jonathan from somewhere in Germany, but now living in
          Copenhagen. If you're coming by, say hi! We can promise we have
          instant coffee and some chocolate at the very least!
        </p>
        <h2>About this Project</h2>
        <p>
          Have you ever wondered what the legal situation is for taking street
          photos, flying your drone or anything else photography related and not
          only in your home country, but anywhere in the world? We have! That's
          why we made this site. We love travelling and obviously we don't want
          to break the law, so we wanted to make our research available to
          others as well!
        </p>
        <p>We're not lawyers, but we're gathering references, excerpts, summaries and how previous legal cases went in different countries to give photographers a better understanding of what their rights and the rights of people around them are.</p>
        <p>
          You go photography community, you're awesome! We made this website
          open source, because we want the community to own it. If you
          contribute, you should be allowed to keep a copy, because you made it
          better.
        </p>
        <p>If you ever need <a href="https://gegenwind.dk" target="gegenwind">
          Photographers in Copenhagen
          </a> let us know!
        </p>
      </article>
    </div>
  </Layout>
);

export default AboutPage;
